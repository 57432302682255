<template>
  <nav class="navbar subnav d-none d-lg-block">
    <div class="container">
      <div class="main">
        <router-link to="/resources">Resources</router-link>
      </div>
    </div>
  </nav>
  <nav class="navbar mobview d-block d-lg-none">
    <div class="border-bottom">
      <div class="container main">
        <router-link to="/resources">Resources</router-link>
        <select
          name="all_categories"
          id="resource_categories"
          class="float-end text-indigo border-0"
          v-model="activeCategoryId"
          @change="handleCategoryChange"
        >
          <option value="0">All Categories</option>
          <option value="-1">Recently Updated</option>
          <template v-for="(item, index) in sortedArray">
            <optgroup v-if="item.hasChildren" :label="item.label">
              <option
                :value="subcategory.id"
                v-for="subcategory in item.subcategories"
                :key="subcategory.id"
              >
                {{ subcategory.name }} ({{ resourceLength(subcategory) }})
              </option>
            </optgroup>
            <option v-else :value="item.id" :key="item.id" class="fw-bold">
              {{ item.label }} ({{ resourceLength(item) }})
            </option>
          </template>
        </select>
      </div>
    </div>
  </nav>
  <div class="min-vh-100 d-flex flex-column">
    <section>
      <div class="container mb-5 mt-5">
        <div class="d-none d-lg-block">
          <div class="row">
            <div class="col-md-3 resource-category">
              <div class="resource-sidebar">
                <div class="category-list">
                  <div
                    class="col-md-12 card-text border-bottom"
                    @click="resourceFiles(0)"
                    :class="{ active: !activeCategoryId && !newResource }"
                  >
                    All Resources ({{ totalResources("all") }})
                  </div>
                  <div
                    class="col-md-12 card-text border-bottom"
                    @click="resourceFiles(-1)"
                    :class="{ active: activeCategoryId == '-1' }"
                  >
                    Recently Updated ({{ totalResources("recent") }})
                  </div>
                  <div
                    class="border-bottom"
                    v-for="(item, index) in sortedArray"
                    :key="index"
                  >
                    <div
                      v-if="!item.hasChildren"
                      class="card-text"
                      :id="'category-' + item.id"
                      @click="resourceFiles(item.id)"
                      :class="{ active: isActiveCategory(item.id) }"
                    >
                      {{ item.label }} ({{ resourceLength(item) }})
                    </div>
                    <div v-else class="category-disabled">
                      {{ item.label }}
                    </div>
                    <div
                      v-for="subcategory in item.subcategories"
                      class="card-text ps-3"
                      @click="resourceFiles(subcategory.id)"
                      :id="'category-' + subcategory.id"
                      :class="{ active: isActiveCategory(subcategory.id) }"
                    >
                      {{ subcategory.name }} ({{ resourceLength(subcategory) }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="col-md-12">
                <div class="mb-4">
                  <div class="row">
                    <div class="col-lg-10">
                      <input
                        type="search"
                        name="search"
                        v-model="searchText"
                        class="form-control border"
                        placeholder="Search Resources"
                        @input="resourceSearch"
                      />
                    </div>
                    <div class="col-lg-2 ps-0">
                      <button
                        type="button"
                        class="btn clear-button w-100"
                        :disabled="showSpinner"
                        @click="clearInput"
                      >
                        <span v-if="showSpinner"
                          ><i class="fas fa-spinner fa-spin"></i> Loading</span
                        >
                        <span v-else>Reset</span>
                      </button>
                    </div>
                    <div class="pt-2" v-if="searchButtons.length">
                      <template v-for="(searchdata, index) in searchButtons">
                        <button
                          class="btn search-button me-2"
                          @click="searchQuery(searchdata.search_criteria)"
                        >
                          {{ searchdata.search_button }}
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="(category, index) in filterResources">
                <div class="card mb-4" v-if="category.resources.length">
                  <div class="card-header">{{ category.name }}</div>
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item"
                      v-for="resource in category.resources"
                    >
                      <div class="py-2 card-text">
                        <a
                          v-if="resource.resource_link"
                          :href="resource.resource_link"
                          @click="resourceAction(resource)"
                          target="_blank"
                          class="resource-title"
                          ><font-awesome-icon
                            :icon="['fa', checkLinkType(resource)]"
                            class="pad-right"
                          />{{ resource.name }}
                        </a>
                        <a
                          v-else
                          :href="`${getAPIUrl}/storage/${resource.file_path}`"
                          @click="resourceAction(resource)"
                          target="_blank"
                          class="resource-title"
                          ><font-awesome-icon
                            :icon="['fa', checkLinkType(resource)]"
                            class="pad-right"
                          />{{ resource.name }} </a
                        ><span class="resource-date" v-if="resource.rev_date">{{
                          formatDate(resource.rev_date)
                        }}</span>
                        <span
                          class="badge resource-badge"
                          v-if="isRecentlyUpdated(resource.rev_date)"
                          >Recently Updated</span
                        >
                        <div class="mt-2">
                          <span class="fw-700" v-html="resource.description">
                          </span>
                          <span
                            class="resource-filesize"
                            v-if="resource.file_size"
                            >{{
                              resource.file_size / (1024 * 1024) < 1
                                ? "<1"
                                : (resource.file_size / (1024 * 1024)).toFixed()
                            }}
                            MB</span
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-4">
                <div class="row mb-2">
                  <div class="col-md-10 resource-search-pad">
                    <input
                      type="search"
                      name="search"
                      v-model="searchText"
                      class="form-control border"
                      placeholder="Search Resources"
                      @input="resourceSearch"
                    />
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="btn clear-button w-100"
                      :disabled="showSpinner"
                      @click="clearInput"
                    >
                      <span v-if="showSpinner"
                        ><i class="fas fa-spinner fa-spin"></i> Loading</span
                      >
                      <span v-else>Reset</span>
                    </button>
                  </div>
                </div>
                <div v-if="searchButtons.length">
                  <template v-for="(searchdata, index) in searchButtons">
                    <button
                      class="btn search-button me-2"
                      @click="searchQuery(searchdata.search_criteria)"
                    >
                      {{ searchdata.search_button }}
                    </button>
                  </template>
                </div>
              </div>
              <div v-for="(category, index) in filterResources">
                <div class="card mb-4" v-if="category.resources.length">
                  <div class="card-header">{{ category.name }}</div>
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item"
                      v-for="resource in category.resources"
                    >
                      <div class="py-2 card-text">
                        <a
                          v-if="resource.resource_link"
                          :href="resource.resource_link"
                          @click="resourceAction(resource)"
                          target="_blank"
                          class="resource-title"
                          ><font-awesome-icon
                            :icon="['fa pe-1', checkLinkType(resource)]"
                            class="pad-right"
                          />{{ resource.name }} </a
                        ><a
                          v-else
                          :href="`${getAPIUrl}/storage/${resource.file_path}`"
                          @click="resourceAction(resource)"
                          target="_blank"
                          class="resource-title"
                          ><font-awesome-icon
                            :icon="['fa', checkLinkType(resource)]"
                            class="pad-right"
                          />{{ resource.name }} </a
                        ><span class="resource-date" v-if="resource.rev_date">{{
                          formatDate(resource.rev_date)
                        }}</span
                        ><span
                          class="badge resource-badge"
                          v-if="isRecentlyUpdated(resource.rev_date)"
                          >Recently Updated</span
                        >
                        <div class="mt-2">
                          <span class="fw-700" v-html="resource.description">
                          </span>
                          <span
                            class="resource-filesize"
                            v-if="resource.file_size"
                            >{{
                              resource.file_size / (1024 * 1024) < 1
                                ? "<1"
                                : (resource.file_size / (1024 * 1024)).toFixed()
                            }}
                            MB</span
                          >
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      activeCategoryId: 0,
      activeCategory: "",
      searchText: "",
      resources: [],
      categories: [],
      sortedArray: [],
      categoryArray: [],
      subcategoryArray: [],
      subcategories: [],
      parentCategories: [],
      resourcePermissions: [],
      filterResourceIds: [],
      filterResources: [],
      filterResourceData: [],
      searchButtons: [],
      isSearchValid: false,
      showClear: false,
      showSpinner: false,
      newResource: false,
    };
  },
  computed: {
    getAPIUrl() {
      const base = this.$axios.defaults.baseURL;
      return base.split("/api")[0];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isActiveCategory() {
      return (categoryId) => {
        return categoryId === this.activeCategoryId;
      };
    },
  },
  created() {
    this.getPermissions();
    this.getResources();
    this.getResourceButtons();
  },
  methods: {
    clearInput() {
      window.location.hash = "";
      this.activeCategoryId = 0;
      this.searchText = "";
      this.activeCategory = "";
      this.showClear = false;
      this.isSearchValid = false;
      this.filterResourceIds = [];
      this.showSpinner = false;
      this.newResource = false;
      this.getResources();
    },
    isRecentlyUpdated(revDate) {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const updatedDate = new Date(revDate);
      const utcThirtyDaysAgo = new Date(
        Date.UTC(
          thirtyDaysAgo.getFullYear(),
          thirtyDaysAgo.getMonth(),
          thirtyDaysAgo.getDate()
        )
      );

      const utcUpdatedDate = new Date(
        Date.UTC(
          updatedDate.getFullYear(),
          updatedDate.getMonth(),
          updatedDate.getDate()
        )
      );
      return utcUpdatedDate >= utcThirtyDaysAgo;
    },
    getResourceButtons() {
      this.$axios.get("resource-search/buttons", {}).then(
        function (response) {
          this.searchButtons = response.data;
        }.bind(this)
      );
    },
    searchQuery(searchTerm) {
      this.filterResources = [];
      this.filterResourceIds = [];
      this.searchText = searchTerm;
      this.resourceSearch();
    },
    resourceSearch() {
      this.showSpinner = true;
      this.isSearchValid = this.searchText != "" ? true : false;
      if (this.isSearchValid) {
        window.location.hash = "";
        this.activeCategoryId = this.newResource ? -1 : 0;
        this.filterResources = [];
        this.filterResourceIds = [];
        if (this.searchText.length > 1) {
          let entityClassId = this.currentUser.user.entity
            ? this.currentUser.user.entity.entity_class_id
            : null;
          let userId = this.currentUser.user.id;
          this.$axios
            .post("resource-search/" + userId, {
              entityClassId: entityClassId,
              search: this.searchText,
            })
            .then(
              function (response) {
                let filtered = response.data;
                if (filtered.length > 0) {
                  this.filterResourceIds = filtered.map((item) => item.id);
                  this.resourceFilter();
                } else {
                  this.showSpinner = false;
                }
              }.bind(this)
            );
        } else {
          this.showSpinner = false;
        }
      } else {
        this.clearInput();
      }
    },
    totalResources(fiterType) {
      let total = 0;
      if (!this.isSearchValid && this.filterResourceIds.length > 0) {
        this.filterResourceIds = [];
      }
      this.filterResourceData.forEach((post) => {
        let data = [];
        data = fiterType == "all" ? post.resources : post.recent_resources;
        if (this.filterResourceIds.length > 0) {
          data = data.filter((item) =>
            this.filterResourceIds.includes(item.id)
          );
        }
        total += data.length;
      });
      if (this.isSearchValid && this.filterResourceIds.length == 0) {
        total = 0;
      }
      return total;
    },
    resourceLength(category) {
      let resources = category.resources;
      let resourceData = [];
      if (resources.length > 0) {
        resourceData = resources.filter((item) =>
          this.checkResourcePermission(item.id)
        );

        if (!this.isSearchValid && this.filterResourceIds.length > 0) {
          this.filterResourceIds = [];
        }

        if (this.filterResourceIds.length > 0) {
          resourceData = resourceData.filter((item) =>
            this.filterResourceIds.includes(item.id)
          );
        }

        if (this.newResource) {
          resourceData = resourceData.filter((item) =>
            this.isRecentlyUpdated(item.rev_date)
          );
        }

        if (this.isSearchValid && this.filterResourceIds.length == 0) {
          resourceData = [];
        }
      }
      return resourceData.length;
    },
    getPermissions() {
      let entityClassId = this.currentUser.user.entity
        ? this.currentUser.user.entity.entity_class_id
        : null;
      let userId = this.currentUser.user.id;
      this.$axios
        .post("resource/permissions/" + userId, {
          entityClassId: entityClassId,
        })
        .then(
          function (response) {
            const permissions = response.data.permissions || [];

            if (permissions.length > 0) {
              this.resourcePermissions = permissions;
            }
          }.bind(this)
        );
    },
    getResources() {
      this.activeCategoryId = 0;
      this.filterResourceIds = [];
      this.filterResourceData = [];
      this.resources = [];
      this.categoryArray = [];
      this.subcategoryArray = [];
      this.sortedArray = [];
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios.get("resources", {}).then(
        function (response) {
          loader.hide();
          this.filterResources = [];

          this.categories = response.data;
          for (let i = 0; i < this.categories.length; i++) {
            if (!this.sortedArray.includes(this.categories[i].name)) {
              this.sortedArray.push({
                id: this.categories[i].id,
                label: this.categories[i].name,
                parent: this.categories[i].parent_id,
                hasChildren: this.categories[i].subcategories.length
                  ? true
                  : false,
                subcategories: this.categories[i].subcategories,
                resources: this.categories[i].resources,
                isActive: false,
              });
            }
            let mainDataArray = [];
            this.categoryArray[this.categories[i].id] = this.categories[i];
            if (this.categories[i].resources.length > 0) {
              let resourceData = this.categories[i].resources.filter((item) =>
                this.checkResourcePermission(item.id)
              );
              if (this.categories[i].sort_type == "newest_first") {
                resourceData.sort(
                  (a, b) => new Date(b.rev_date) - new Date(a.rev_date)
                );
              } else {
                resourceData.sort((a, b) => a.sort_order - b.sort_order);
              }

              let recentResources = resourceData.filter((item) =>
                this.isRecentlyUpdated(item.rev_date)
              );
              mainDataArray = {
                name: this.categories[i].name,
                resources: resourceData,
                recent_resources: recentResources,
              };
              this.resources[this.categories[i].id] = mainDataArray;
              this.filterResources.push(mainDataArray);
            }

            this.subcategories = [];
            if (this.categories[i].subcategories.length > 0) {
              let subcatIdsArray = [];
              this.subcategories = this.categories[i].subcategories;
              for (let j = 0; j < this.subcategories.length; j++) {
                this.categoryArray[this.subcategories[j].id] =
                  this.subcategories[j];

                subcatIdsArray.push(this.subcategories[j].id);
                let subDataArray = [];
                if (this.subcategories[j].resources.length > 0) {
                  let resourceData = this.subcategories[j].resources.filter(
                    (item) => this.checkResourcePermission(item.id)
                  );
                  if (this.subcategories[j].sort_type == "newest_first") {
                    resourceData.sort(
                      (a, b) => new Date(b.rev_date) - new Date(a.rev_date)
                    );
                  } else {
                    resourceData.sort((a, b) => a.sort_order - b.sort_order);
                  }

                  let recentResources = resourceData.filter((item) =>
                    this.isRecentlyUpdated(item.rev_date)
                  );
                  subDataArray = {
                    name: this.subcategories[j].name,
                    resources: resourceData,
                    recent_resources: recentResources,
                  };
                  this.resources[this.subcategories[j].id] = subDataArray;
                  this.filterResources.push(subDataArray);
                }
              }
              this.subcategoryArray[this.categories[i].id] = subcatIdsArray;
            }
          }
          if (!this.isSearchValid) {
            this.filterResourceData = this.filterResources;
          }
          this.updateActions("/resources");

          // Get the current hash value from the URL
          let currentHash = window.location.hash;
          if (currentHash != "#recent") {
            let hashValues = currentHash.split("?");
            let category = this.findCategoryByHash(hashValues[0]);
            if (hashValues[1]) {
              this.newResource = true;
            }
            if (category) {
              this.resourceFiles(category.id);
            }
          } else {
            this.resourceFiles(-1);
          }
        }.bind(this)
      );
    },
    findCategoryByHash(hash) {
      return Object.values(this.categoryArray).find(
        (category) => `#${category.path_end}` == hash
      );
    },
    handleCategoryChange(event) {
      const selectedCategoryId = event.target.value;
      this.resourceFiles(selectedCategoryId);
    },
    checkLinkType(post) {
      let linkIcon = "file";
      let category = this.categoryArray[post.category_id];
      const resourceTypeLinks = [
        "training",
        "blogs",
        "press-releases",
        "podcasts",
      ];
      const resourceTypeVideos = ["videos", "events", "webinars"];
      if (resourceTypeVideos.includes(category.path_end)) {
        linkIcon = "video";
      } else if (resourceTypeLinks.includes(category.path_end)) {
        linkIcon = "link";
      }
      return linkIcon;
    },
    resourceFiles(categoryId) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.activeCategoryId = 0;
      let resourceData = [];
      if (categoryId < 0) {
        this.activeCategoryId = categoryId;
        this.newResource = true;
        this.activeCategory = "recent";
        window.location.hash = `#recent`;
      }
      if (categoryId > 0) {
        this.activeCategoryId = categoryId;
        let resourceCategory = this.categoryArray[this.activeCategoryId];
        this.activeCategory = resourceCategory.path_end;

        if (this.newResource) {
          window.location.hash = `#${this.activeCategory}?recent=1`;
        } else {
          window.location.hash = `#${this.activeCategory}`;
        }

        this.filterResources = [];
        if (this.resources[categoryId]) {
          resourceData = this.newResource
            ? this.resources[categoryId].recent_resources
            : this.resources[categoryId].resources;
          if (this.filterResourceIds.length > 0) {
            resourceData = resourceData.filter((item) =>
              this.filterResourceIds.includes(item.id)
            );
          }
          if (resourceData) {
            const elementWithResources = {
              name: this.resources[categoryId].name,
              resources: resourceData,
            };
            this.filterResources.push(elementWithResources);
          }
        }
      } else if (categoryId == 0) {
        this.newResource = false;
        this.activeCategory = "";
        window.location.hash = "";
        this.resourceFilter();
      } else {
        this.resourceFilter();
      }
    },
    resourceFilter() {
      if (!this.isSearchValid && this.filterResourceIds.length > 0) {
        this.filterResourceIds = [];
      }
      if (this.filterResourceData.length > 0) {
        this.showSpinner = false;
        this.filterResources = [];
        this.filterResourceData.forEach((post) => {
          let data = [];
          data = this.newResource ? post.recent_resources : post.resources;
          if (this.filterResourceIds.length > 0) {
            data = data.filter((item) =>
              this.filterResourceIds.includes(item.id)
            );
          }
          if (data) {
            const elementWithResources = {
              name: post.name,
              resources: data,
            };
            this.filterResources.push(elementWithResources);
          }
        });
      }
    },
    resourceAction(resource) {
      let actionJson = {};
      if (resource.resource_link) {
        actionJson = {
          pagelink: resource.resource_link,
        };
      } else {
        const paddedNumber = resource.id.toString().padStart(4, "0");
        let pagelink = `lib${paddedNumber}: ${resource.name}`;
        actionJson = {
          pagelink: pagelink,
          action: "downloaded",
          contentClass: "libe entry",
        };
      }

      let userId = this.currentUser.user.id;
      if (userId) {
        actionJson.userId = userId;
        actionJson.email = this.currentUser.user.email;
        actionJson.sessionId = this.currentUser.sessionId;
      }

      const platform = process.env.VUE_APP_PLATFORM;

      this.$axios
        .post("action-update", actionJson)
        .then(() => {
          console.log("Action logged successfully");

          if (platform === "production") {
            if (window._hsq) {
              window._hsq.push([
                "trackCustomBehavioralEvent",
                {
                  name: "pe20495833_view_resource_v3",
                  properties: {
                    resource_id: resource.id,
                    resource_name: resource.name,
                  },
                },
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Failed to log action:", error);
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString)
        .toLocaleDateString("en-US", {
          timeZone: "UTC",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, ".");
      return date;
    },
    checkResourcePermission(resourceId) {
      return this.currentUser && this.resourcePermissions.includes(resourceId);
    },
  },
};
</script>
