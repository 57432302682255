export default {
  methods: {
    updateActions(pagelink) {
      let userId = this.currentUser?.user?.id;
      let actionJson = {
        pagelink: pagelink,
      };

      if (userId) {
        actionJson.userId = userId;
        actionJson.email = this.currentUser.user.email;
        actionJson.sessionId = this.currentUser.sessionId;
      }

      const platform = process.env.VUE_APP_PLATFORM;

      this.$axios.post("action-update", actionJson).then(() => {
        console.log("Action logged successfully");

        if (platform == "production") {
          // Track event in HubSpot
          window._hsq = window._hsq || [];
          window._hsq.push(["setPath", actionJson.pagelink]);
          window._hsq.push(["setTitle", document.title]);
          window._hsq.push(["trackPageView"]);
        }
      });
    },
  },
};
