import "@popperjs/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/style.css";
import "semantic-ui-css/semantic.min.css";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios";

import globalMixin from "@/mixins/globalMixin";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// Define the click-outside directive
const clickOutsideDirective = {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      // Check if click was outside the element and its children
      if (
        !(el == event.target || el.contains(event.target)) &&
        !event.target.closest(".sidebar")
      ) {
        // Execute the provided method
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};

library.add(fas, fab, far);
const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(LoadingPlugin);
app.use(router);
app.use(store);
app.mixin(globalMixin);
app.component("font-awesome-icon", FontAwesomeIcon);

// Register the directive globally
app.directive("click-outside", clickOutsideDirective);

app.mount("#app");
