<template>
  <div class="container">
    <div class="bg-grey">
      <div class="row">
        <div
          class="col-sm-12 card-text"
          v-html="supportData.page_content"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      successful: false,
      loading: false,
      supportData: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getSupport() {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios
        .get("static-pages", {
          params: {
            pageName: "support",
          },
        })
        .then(
          function (response) {
            loader.hide();
            this.supportData = response.data[0];
            this.updateActions("/support");
          }.bind(this)
        );
    },
  },
  created() {
    this.getSupport();
  },
};
</script>
