<template>
  <my-header v-if="!hideHeader"></my-header>
  <router-view></router-view>
  <my-footer v-if="!hideFooter"></my-footer>
</template>

<script>
import Header from "./components/layouts/header.vue";
import Footer from "./components/layouts/footer.vue";

export default {
  name: "App",
  components: {
    "my-header": Header,
    "my-footer": Footer,
  },
  data() {
    return {
      hideHeader: true, // Set to true to hide header for Resources component
      hideFooter: true, // Set to true to hide footer for Resources component
    };
  },
  watch: {
    $route(to) {
      const hideForRoutes = ["Tarana Resources"]; // Add routes requiring hidden header/footer
      this.hideHeader = hideForRoutes.includes(to.name);
      this.hideFooter = hideForRoutes.includes(to.name);
    },
  },
  mounted() {
    this.identifyUserWithHubSpot();
  },
  methods: {
    identifyUserWithHubSpot() {
      const platform = process.env.VUE_APP_PLATFORM;
      const hubspotUTK = this.getHubspotUTK();
      const user = this.$store.state.auth?.user;
      const identifyPayload = {};

      // Retrieve user email from URL or store
      let userEmail = this.getUserEmailFromUrl() || (user?.user?.email ?? "");

      // Add email and HubSpot UTK to payload if available

      if (hubspotUTK) {
        identifyPayload.hubspotutk = hubspotUTK;
      }

      if (userEmail) {
        identifyPayload.email = decodeURIComponent(userEmail);
      }

      if (platform === "production") {
        window._hsq = window._hsq || [];
        window._hsq.push(["identify", identifyPayload]);
      }
    },
    getHubspotUTK() {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [key, value] = cookie.trim().split("=");
        if (key === "hubspotutk") {
          return value;
        }
      }
      return null; // Return null if not found
    },
    getHubSpotUTKFromUrl() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("hubspotutk");
      } catch (error) {
        return null;
      }
    },
    getUserEmailFromUrl() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("user_email");
      } catch (error) {
        return null;
      }
    },
  },
};
</script>
