import { createWebHistory, createRouter } from "vue-router";
import { setupRouterGuard } from "./router-guard.js";
import Forbidden from "./components/forbidden.vue";
import Home from "./components/home.vue";
import Login from "./components/login.vue";
import Register from "./components/register.vue";
import Resources from "./components/resources.vue";
import Onboarding from "./components/onboarding.vue";
import TaranaResources from "./components/tarana-resources.vue";
import SupplyChain from "./components/supply-chain.vue";
import Support from "./components/support.vue";
import Platforms from "./components/platforms.vue";
import Account from "./components/account.vue";
import Forgot from "./components/forgot.vue";
import ConfirmEmail from "./components/confirm-email.vue";
import VerifyToken from "./components/verify-token.vue";
import ResetPasswordForm from "./components/reset-password-form.vue";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: () => import("./components/error.vue"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: Forbidden,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/confirm-email/:token",
    name: "Confirm Email",
    component: ConfirmEmail,
    meta: {
      hideForAuth: false,
    },
  },
  {
    path: "/verify-token/:token",
    name: "Verify Token",
    component: VerifyToken,
    meta: {
      hideForAuth: false,
    },
  },
  {
    path: "/forgot",
    name: "Forgot Password",
    component: Forgot,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/reset-password/:token/:email",
    name: "Reset Password",
    component: ResetPasswordForm,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/account",
    name: "My Account",
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tarana-resources",
    name: "Tarana Resources",
    component: TaranaResources,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/supply-chain",
    name: "Supply Chain",
    component: SupplyChain,
    meta: {
      requiresAuth: true,
      permission: "supply-chain",
    },
  },
  {
    path: "/onboarding/:page_name",
    name: "Customer Onboarding",
    component: Onboarding,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/platforms/:page_name",
    name: "G1 Platform",
    component: Platforms,
    meta: {
      requiresAuth: false,
      permissions: ["link-gallery"],
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  mode: "hash",
  scrollBehavior(to) {
    if (to.name != "Tarana Resources") {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const defaultTitle = "Tarana Wireless";
  // Helper function to format titles
  const formatTitle = (str) =>
    str
      ? str
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "";
  const pageNameMap = {
    cto: "CTO",
    specs: "Specifications",
    "noc-operator": "NOC Operator",
  };

  let titleParts = [to.name ?? "Home"];
  if (to.params.page_name) {
    const readableTitle =
      pageNameMap[to.params.page_name] || formatTitle(to.params.page_name);
    titleParts.push(readableTitle);
  }
  if (to.hash) {
    titleParts.push(formatTitle(to.hash.replace("#", "")));
  }
  titleParts.push(defaultTitle);
  document.title = titleParts.filter(Boolean).join(" - ");

  next();
});

setupRouterGuard(router);

export default router;
