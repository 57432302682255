<template>
  <nav class="navbar subnav d-none d-lg-block">
    <div class="container">
      <div class="main">
        <router-link
          :to="{ path: '/platforms/network-design' }"
          @click="gotoPage()"
          >G1 Platform</router-link
        >
      </div>
      <ul class="float-end mb-0 menu">
        <li
          class="parent"
          v-for="(item, index) in sortedArray"
          :key="index"
          :class="{
            item: true,
            active: item.isActive,
            'd-none': !item.urlPermission,
          }"
        >
          <router-link
            :to="{ path: '/platforms/' + item.urlparam }"
            @click="toggleActive(item)"
            >{{ item.label }}</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
  <nav class="navbar mobview d-block d-lg-none">
    <div class="border-bottom">
      <div class="container main">
        <router-link
          :to="{ path: '/platforms/network-design' }"
          @click="gotoPage()"
          >G1 Platform</router-link
        >
      </div>
    </div>
    <div class="mobmenu">
      <div class="container">
        <ul>
          <li
            class="parent"
            v-for="(item, index) in sortedArray"
            :key="index"
            :class="{ item: true, active: item.isActive }"
          >
            <router-link
              :to="{ path: '/platforms/' + item.urlparam }"
              @click="toggleActive(item)"
              >{{ item.label }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    class="min-vh-100 d-flex flex-column"
    :id="showSubCategories ? 'subcat' : 'maincat'"
  >
    <section>
      <div class="submenu" v-if="showSubCategories">
        <ul class="text-center">
          <li
            class="btn"
            v-for="(item, index) in subcatArray[currentCategory]"
            :key="index"
            :class="{ item: true, active: item.isActive }"
            @click="toggleSubActive(item)"
          >
            <a :href="'#' + item.urlparam">{{ item.label }}</a>
          </li>
        </ul>
      </div>
      <network-design
        v-if="currentPage == 'network-design'"
        :base-url="getAPIUrl"
        :platform-data="platformList"
      ></network-design>
      <specifications
        v-if="currentPage == 'specs'"
        :specs-data="specDetails"
        :legends-list="legends"
      ></specifications>
      <network-economics
        v-if="currentPage == 'network-economics'"
        :platform-data="platformList"
      ></network-economics>
      <management
        v-if="currentPage == 'management'"
        :base-url="getAPIUrl"
        :platform-data="platformList"
      ></management>
      <link-gallery
        v-if="currentPage == 'link-gallery'"
        :base-url="getAPIUrl"
        :platform-data="platformList"
      ></link-gallery>
      <configuration
        v-if="currentPage == 'configuration'"
        :base-url="getAPIUrl"
        :platform-data="platformList"
      ></configuration>
    </section>
  </div>
</template>
<script>
import NetworkDesign from "./network-design.vue";
import Specifications from "./specifications.vue";
import NetworkEconomics from "./network-economics.vue";
import Management from "./management.vue";
import LinkGallery from "./link-gallery.vue";
import Configuration from "./configuration.vue";
import { hasPermission } from "@/router-guard";
export default {
  components: {
    "network-design": NetworkDesign,
    specifications: Specifications,
    "network-economics": NetworkEconomics,
    management: Management,
    "link-gallery": LinkGallery,
    configuration: Configuration,
  },
  data() {
    return {
      currentPage: "",
      currentCategory: 0,
      showSubCategories: false,
      platforms: [],
      categories: [],
      sortedArray: [],
      subcategories: [],
      subcatArray: [],
      platformList: [],
      specDetails: [],
      legends: [],
    };
  },
  computed: {
    getAPIUrl() {
      const base = this.$axios.defaults.baseURL;
      return base.split("/api")[0];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    gotoPage() {
      let activePage = this.sortedArray.filter(
        (item) => item.urlparam == "network-design"
      );
      this.toggleActive(activePage[0]);
    },
    toggleActive(item) {
      this.currentCategory = item.id;
      this.sortedArray.forEach((item) => {
        item.isActive = false;
      });
      this.currentPage = item.urlparam;
      let clickURL = "/platforms/" + this.currentPage;
      localStorage.setItem("lastVisitedURL", clickURL);
      if (item.hasChildren) {
        this.showSubCategories = true;
        if (location.hash) {
          let activePage = this.subcatArray[this.currentCategory].filter(
            (item) => item.urlparam == location.hash.slice(1)
          );
          if (activePage.length > 0) {
            if (activePage[0].hasPlatform) {
              this.toggleSubActive(activePage[0]);
            }
          } else {
            this.platformList = this.platforms[item.id];
          }
        } else {
          if (this.subcatArray[this.currentCategory][0].hasPlatform) {
            this.toggleSubActive(this.subcatArray[this.currentCategory][0]);
          } else {
            this.platformList = this.platforms[item.id];
          }
        }
      } else {
        if (item.label == "Specs") {
          this.specDetails = this.getSpecification(item.id);
        } else {
          this.platformList = this.platforms[item.id];
        }
        this.showSubCategories = false;
      }
      item.isActive = true;
      this.updateActions("/platforms/" + this.currentPage);
    },
    toggleSubActive(item) {
      let pagelink = this.currentPage + "/" + item.urlparam;
      let clickURL = "/platforms/" + this.currentPage + "#" + item.urlparam;
      localStorage.setItem("lastVisitedURL", clickURL);
      this.subcatArray[this.currentCategory].forEach((item) => {
        item.isActive = false;
      });

      this.platformList = this.platforms[item.id];
      item.isActive = true;
      this.updateActions("/platforms/" + pagelink);
    },
    getPlatforms() {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios.get("platforms", {}).then(
        function (response) {
          loader.hide();
          this.categories = response.data;
          for (let i = 0; i < this.categories.length; i++) {
            if (!this.sortedArray.includes(this.categories[i].name)) {
              var urlparam = this.categories[i].name
                .replace(/\s+/g, "-")
                .toLowerCase();

              let accessPermission = true;
              if (this.$route.meta.permissions.includes(urlparam)) {
                accessPermission = hasPermission(urlparam);
              }

              this.sortedArray.push({
                id: this.categories[i].id,
                label: this.categories[i].name,
                urlparam: urlparam,
                isActive: false,
                hasChildren: this.categories[i].subcategories.length
                  ? true
                  : false,
                subcategories: this.categories[i].subcategories,
                urlPermission: accessPermission,
              });

              this.platforms[this.categories[i].id] =
                this.categories[i].platforms;
            }

            this.subcategories = [];
            if (this.categories[i].subcategories.length > 0) {
              this.subcategories = this.categories[i].subcategories;
              let dataArray = [];
              for (let j = 0; j < this.subcategories.length; j++) {
                if (!this.subcatArray.includes(this.subcategories[j].name)) {
                  var urlparam = this.subcategories[j].name
                    .replace(/\s+/g, "-")
                    .toLowerCase();

                  dataArray.push({
                    id: this.subcategories[j].id,
                    label: this.subcategories[j].name,
                    category_id: this.categories[i].id,
                    urlparam: urlparam,
                    hasPlatform: this.subcategories[j].platforms.length
                      ? true
                      : false,
                    isActive: false,
                  });

                  if (this.subcategories[j].platforms) {
                    this.platforms[this.subcategories[j].id] =
                      this.subcategories[j].platforms;
                  }
                }
              }

              this.subcatArray[this.categories[i].id] = dataArray;
            }
          }
          if (this.$route.params.page_name) {
            let activePage = this.sortedArray.filter(
              (item) => item.urlparam == this.$route.params.page_name
            );
            this.toggleActive(activePage[0]);
          }
        }.bind(this)
      );
    },
    getSpecification(id) {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios
        .get("specs", {
          params: {
            categoryID: id,
          },
        })
        .then(
          function (response) {
            loader.hide();
            this.specDetails = response.data.specifications;
            this.legends = response.data.legends;
          }.bind(this)
        );
    },
  },
  created() {
    this.getPlatforms();
  },
};
</script>
