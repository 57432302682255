<template>
  <div class="supply-chain-banner">
    <span class="mask bg-gradient-dark"></span>
    <div class="chain-banner-text">
      <div class="main-heading">Supply Chain Portal</div>
      <div class="sub-heading">
        Welcome to SCM Portal. An essential hub to all transactions involving
        Supply Chain.
      </div>
    </div>
  </div>

  <div class="container d-flex flex-column home supply-chain">
    <div class="row">
      <div class="col-md-12 forms-text px-4">
        {{ supplyChainData.length }} forms listed
      </div>
      <div
        class="col-md-6 col-sm-12 px-4"
        v-for="(supplyChain, index) in supplyChainData"
      >
        <div class="card">
          <div class="card-body">
            <div class="chain-content-height">
              <h5 class="card-title">{{ supplyChain.title }}</h5>
              <div
                class="card-text truncate"
                v-html="supplyChain.description"
              ></div>
            </div>
            <div class="card-text contact-text">
              <span v-html="formatContacts(supplyChain.contacts)"></span>
            </div>
            <div class="chain-footer-height">
              <div id="process-view">
                <div v-if="supplyChain.process_view_type == 'Link'">
                  <a
                    v-if="supplyChain.process_details_link"
                    :href="supplyChain.process_details_link"
                    target="_blank"
                    class="view-link"
                    >View Process Details
                    <img
                      src="../assets/images/edit.png"
                      alt=""
                      class="d-inline-block ps-1"
                  /></a>
                </div>
                <div class="dropdown" v-else>
                  <a
                    href="#"
                    :id="'processDropdown' + index"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    class="view-link dropdown-button dropdown-toggle"
                  >
                    View Process Details
                  </a>
                  <ul class="dropdown-menu">
                    <li
                      v-if="supplyChain.process_link"
                      class="cursor-pointer"
                      @click="gotoLink(supplyChain.process_link)"
                    >
                      Process
                      <img
                        src="../assets/images/edit-grey.png"
                        alt=""
                        class="d-inline-block pt-1 float-end"
                      />
                    </li>
                    <li
                      v-if="supplyChain.process_flow_link"
                      class="cursor-pointer"
                      @click="gotoLink(supplyChain.process_flow_link)"
                    >
                      Process Flow<img
                        src="../assets/images/edit-grey.png"
                        alt=""
                        class="d-inline-block pt-1 float-end"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <a
                class="request-button float-end"
                v-if="
                  supplyChain.button_type == 'Request' &&
                  supplyChain.request_button_link
                "
                :href="supplyChain.request_button_link"
                target="_blank"
              >
                Open a Request
              </a>
              <div
                class="dropdown float-end"
                id="template-btn"
                v-if="
                  supplyChain.button_type == 'Template' &&
                  (supplyChain.flex_link || supplyChain.sanmina_link)
                "
              >
                <button
                  :id="'templateDropdown' + index"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  class="request-button float-end dropdown-button dropdown-toggle"
                >
                  Open a Template
                </button>
                <ul class="dropdown-menu">
                  <li
                    v-if="supplyChain.flex_link"
                    class="cursor-pointer"
                    @click="gotoLink(supplyChain.flex_link)"
                  >
                    Flex
                    <img
                      src="../assets/images/edit-grey.png"
                      alt=""
                      class="d-inline-block pt-1 float-end"
                    />
                  </li>
                  <li
                    v-if="supplyChain.sanmina_link"
                    class="cursor-pointer"
                    @click="gotoLink(supplyChain.sanmina_link)"
                  >
                    Sanmina<img
                      src="../assets/images/edit-grey.png"
                      alt=""
                      class="d-inline-block pt-1 float-end"
                    />
                  </li>
                </ul>
              </div>
              <div
                id="template-modal"
                v-if="supplyChain.button_type == 'Template'"
              >
                <button
                  data-bs-toggle="modal"
                  class="request-button float-end"
                  :data-bs-target="'#templateModal' + index"
                >
                  Open a Template <span class="caret"></span>
                </button>
                <div
                  class="modal fade"
                  :id="'templateModal' + index"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  data-bs-backdrop="false"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel">
                          {{ supplyChain.title }}
                        </h4>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div
                          v-if="supplyChain.flex_link"
                          class="cursor-pointer"
                          @click="gotoLink(supplyChain.flex_link)"
                        >
                          Flex
                          <img
                            src="../assets/images/edit-grey.png"
                            alt=""
                            class="d-inline-block pt-1 float-end"
                          />
                        </div>
                        <div
                          v-if="supplyChain.sanmina_link"
                          class="cursor-pointer"
                          @click="gotoLink(supplyChain.sanmina_link)"
                        >
                          Sanmina<img
                            src="../assets/images/edit-grey.png"
                            alt=""
                            class="d-inline-block pt-1 float-end"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 bottom-text px-4">
        List ends here<br />
        <a href="#" @click="scrollToTop" class="text-dark">Back to Top</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      successful: false,
      loading: false,
      supplyChainData: [],
    };
  },
  computed: {
    getAPIUrl() {
      const base = this.$axios.defaults.baseURL;
      return base.split("/api")[0];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    gotoLink(link) {
      if (link) window.open(link, "_blank");
    },
    scrollToTop() {
      // Use window.scrollTo to scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Add smooth scrolling effect
      });
    },
    formatContacts(contacts) {
      const emailArray = contacts.split(", ");
      let contactText = emailArray.length > 1 ? "Contacts: " : "Contact: ";
      const emailLinks = emailArray.map((email) => {
        return `<a href="mailto:${email}" class="text-dark">${email}</a>`;
      });
      return contactText + emailLinks.join(", ");
    },
    getSupplyChain() {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios.get("supply-chains", {}).then(
        function (response) {
          loader.hide();
          this.supplyChainData = response.data;
        }.bind(this)
      );
    },
  },
  created() {
    this.getSupplyChain();
    this.updateActions("/supply-chain");
  },
};
</script>
